import React, {useEffect, useState} from 'react';
import Frame from 'components/Frame/Frame';
import {Checkbox, Col, Dropdown, Form, Menu, message, Modal, Row} from "antd";
import DlgLabel from "pages/BizPage/GAR_DY/GAR_BOS/DlgLabel.jsx";
import DlgGBOSAltNumber from "pages/BizPage/GAR_DY/GAR_BOS/DlgGBOSAltNumber";
import Tools from "script/sweet-tools.mjs";
import dictData from "hooks/useGlobalData.mjs";
// 增加权限控制
import authenticator from "script/sweet-authenticator.mjs";
import DraggableModal from "components/DraggableModal/DraggableModal";
import ReadyInput from "components/ReadyInput/ReadyInput";
import {firstPbosDict} from "pages/BizPage/COMM/CurrentComm.mjs";
import {useMainTabContext} from 'components/MainTabs/MainTabs.jsx'

const GBOSFrame = ({record, onFrameCall, mainPageQuery, defaultPaneKey, ...props}) => {
    const mainTab = useMainTabContext()
    const {$key} = props; // 当前标签页
    /*FDP("外贸境内加工"),
    FFP("外贸境外加工"),
    FDT("外贸境内经销"),
    FFT("外贸境外经销"),*/

    const [formPrintSet] = Form.useForm();//打印设置
    //是否更新了出运单
    const [change, setChange] = useState(false);

    // 是否经销
    //const isGar = (record?.soModeCode=='FDT' ||record?.soModeCode=='FFT')?true:false
    const [isGar, setIsGar] = useState(false);
    const [dlvBos, setDlvBos] = useState(record);
    // 取运编号
    const [visibleTakeNum, setVisibleTakeNum] = useState(false);
    // 取运编号记录
    const [visibleTakeNumLog, setVisibleTakeNumLog] = useState(false);
    // 唛头
    const [visibleLabel, setVisibleLabel] = useState(false);
    // 修改运编号
    const [visibleAltNum, setVisibleAltNum] = useState(false);
    // 打印设置
    const [visiblePrintSet, setVisiblePrintSet] = useState(false);

    const [loadingConfirm, setLoadingConfirm] = useState(false);

    // 打印类型
    const [printType, setPrintType] = useState("");

    // 上传文件目录
    const [filePath, setFilePath] = useState(firstPbosDict + record.bosNo);

    //是否外贸境外加工
    const [ffpFlag, setFfpFlag] = useState(record.soModeCode === 'FFP');
    const [fdpFlag, setFdpFlag] = useState(record.soModeCode === 'FDP');


    //船务是否交单
    const [docToFlag, setDocToFlag] = useState(false);

    // 单证：权限控制 true 当前用户有编辑权限，false 没有编辑权限
    const [hasEditPermDS, setHasEditPermDS] = useState(false)

    // 单证：改单权限
    const [hasChangePermDS, setHasChangePermDS] = useState(false)

    // 业务：权限控制 true 当前用户有编辑权限，false 没有编辑权限
    const [hasEditPermSM, setHasEditPermSM] = useState(false)

    // 业务：改单权限
    const [hasChangePermSM, setHasChangePermSM] = useState(false)

    // 子页面是否需要设置编辑模式
    const [selfControlEditMode, setSelfControlEditMode] = useState({
        "home": false, // 首页是否需要
        "iniRow": false, // 商品明细是否需要
        "reviewCredit": false,  // 信用险是否需要
        "review": false // 托单评审是否需要
    })

    /**
     * 出运单是否编辑状态：草稿、托单退回、改单、待托单、未退送
     * @param dlvBos
     * @return
     */
    const isEditBosSts = (dlvBos) => {
        return (dlvBos.bosStsCode == "DRAFT" || dlvBos.bosStsCode == "RETURN" || dlvBos.bosStsCode == "ALT" || dlvBos.bosStsCode == "TOSUBMIT") && (dlvBos.cdCancelFlag ?? false) == false;
    }

    useEffect(() => {
        init();
    }, [change]);

    const init = async () => {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post("/dlv/bos/oneDlvBosDTO",
                    {
                        bosId: record.bosId
                    })
            },
            {
                error: {content: (err) => `查询失败: ${err.message}`},
                loading: {show: false},
                success: {show: false},
            }
        );
        if (data?.bos) {
            let bos = data?.bos;
            // 收款方式
            bos.payModeData = data?.payModeData;
            //
            bos.transModeName = dictData.getDictDtlCn("TRANS_MODE", bos.transModeCode);// 出运方式
            bos.soModeName = bos.soModeCode ? dictData.getDictDtlCn("SO_MODE", bos.soModeCode) : '';// 订单模式
            bos.tradeModeName = bos.tradeModeCode ? dictData.getDictDtlCn("TRADE_MODE", bos.tradeModeCode) : '';// 贸易方式
            bos.shipGCatNames = bos.shipGCatCodes ? dictData.getDictDtlCn("SHIP_G_CAT", bos.shipGCatCodes) : '';// 出运商品分类
            bos.coTypeName = bos.coTypeCode ? dictData.getDictDtlCn("CO_TYPE", bos.coTypeCode) : '';// 产地证类型
            bos.packTypeName = bos.packTypeCode ? dictData.getDictDtlCn("PACK_TYPE", bos.packTypeCode) : '';// 包装种类


            // 设置权限
            const hasPermissionEditDS = authenticator.hasPermissionEdit({
                permissionCode: "PBOS-DS",
                billOrgPathNo: bos.orgPathNo,
                billPuId: bos.puId,
                billUserId: bos.crtUserId
            })
            setHasEditPermDS(hasPermissionEditDS);
            const hasPermissionEditSM = authenticator.hasPermissionEdit({
                permissionCode: "PBOS-SM",
                billOrgPathNo: bos.orgPathNo,
                billPuId: bos.puId,
                billUserId: bos.crtUserId
            })
            setHasEditPermSM(hasPermissionEditSM);

            // 改单权限
            const hasPermissionChangeDS = authenticator.hasPermissionChange({
                permissionCode: "PBOS-DS",
                billOrgPathNo: bos.orgPathNo,
                billPuId: bos.puId,
                billUserId: bos.crtUserId
            })
            setHasChangePermDS(hasPermissionChangeDS);
            const hasPermissionChangeSM = authenticator.hasPermissionChange({
                permissionCode: "PBOS-SM",
                billOrgPathNo: bos.orgPathNo,
                billPuId: bos.puId,
                billUserId: bos.crtUserId
            })
            setHasChangePermSM(hasPermissionChangeSM);

            /**
             * 可编辑
             */
            if (isEditBosSts(bos)) {
                bos.canEdit = true;
            } else {
                bos.canEdit = false;
            }

            setDlvBos(bos)

            mainTab.config({'name': bos.bosNo})
            // 设置是否经销
            setIsGar((bos?.soModeCode === 'FDT' || bos?.soModeCode === 'FFT'));


            if (bos.docToFlag) {
                setDocToFlag(true)
            }
        }
    }

    const changeBos = () => {
        setChange(!change);
        if (mainPageQuery) {
            mainPageQuery();
        }
    }

    const printFP = async () => {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.get("/dlv/pbosshiprow/listDlvBosDeclareRowDRs", {
                    bosId: record.bosId
                })
            },
            {
                error: {content: (err) => `查询失败: ${err.message}`},
                loading: {show: false},
                success: {show: false},
            }
        )
        if (data?.length > 0) {
            setPrintType("FP");
            formPrintSet.resetFields();
            formPrintSet.setFieldsValue({
                reportCode: 'invoice_1st_bdr',
                billTo: true,
                companyTitle: true,
                barSeal: true,
            })
            setVisiblePrintSet(true);
        } else {
            message.warning("请先维护报关明细!");
            return;
        }
    }

    const printYFFP = async () => {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.get("/dlv/pbosshiprow/listDlvBosDeclareRowDRs", {
                    bosId: record.bosId
                })
            },
            {
                error: {content: (err) => `查询失败: ${err.message}`},
                loading: {show: false},
                success: {show: false},
            }
        )
        if (data?.length > 0) {
            setPrintType("FP");
            formPrintSet.resetFields();
            formPrintSet.setFieldsValue({
                reportCode: 'invoice_1st_bdr_yf',
                billTo: true,
                companyTitle: true,
                barSeal: true,
            })
            setVisiblePrintSet(true);
        } else {
            message.warning("请先维护报关明细!");
            return;
        }
    }

    const printZXD = async () => {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.get("/dlv/pbosshiprow/listDlvBosDeclareRowDRs", {
                    bosId: record.bosId
                })
            },
            {
                error: {content: (err) => `查询失败: ${err.message}`},
                loading: {show: false},
                success: {show: false},
            }
        )
        if (data?.length > 0) {
            setPrintType("ZXD");
            formPrintSet.resetFields();
            formPrintSet.setFieldsValue({
                reportCode: 'packing_1st_bdr',
                billTo: true,
                companyTitle: true,
                barSeal: true,
            })
            setVisiblePrintSet(true);
        } else {
            message.warning("请先维护报关明细!");
            return;
        }
    }

    const printYFZXD = async () => {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.get("/dlv/pbosshiprow/listDlvBosDeclareRowDRs", {
                    bosId: record.bosId
                })
            },
            {
                error: {content: (err) => `查询失败: ${err.message}`},
                loading: {show: false},
                success: {show: false},
            }
        )
        if (data?.length > 0) {
            setPrintType("ZXD");
            formPrintSet.resetFields();
            formPrintSet.setFieldsValue({
                reportCode: 'packing_1st_bdr_yf',
                billTo: true,
                companyTitle: true,
                barSeal: true,
            })
            setVisiblePrintSet(true);
        } else {
            message.warning("请先维护报关明细!");
            return;
        }
    }

    const printBGD = async () => {
        //const win = window.open('about:blank');
        //win.location.href = "https://crop-1257315785.cos.ap-shanghai.myqcloud.com/sumec/demo/print/%E6%88%90%E5%93%81%E4%BA%A4%E4%BB%98/%E5%87%BA%E5%8F%A3%E6%8A%A5%E5%85%B3%E5%8D%95%E6%89%93%E5%8D%B0%E6%96%87%E6%A1%A3.pdf"
        Tools.openReportView({
            code: 'dlv_bos_declare_row',
            payload: {__filename__: record.bosNo + "_报关单", bosId: record.bosId}
        });
    }
    const printBGDNested = async () => {
        //const win = window.open('about:blank');
        //win.location.href = "https://crop-1257315785.cos.ap-shanghai.myqcloud.com/sumec/demo/print/%E6%88%90%E5%93%81%E4%BA%A4%E4%BB%98/%E5%87%BA%E5%8F%A3%E6%8A%A5%E5%85%B3%E5%8D%95%E6%89%93%E5%8D%B0%E6%96%87%E6%A1%A3.pdf"
        Tools.openReportView({
            code: 'dlv_bos_declare_row_nested',
            payload: {__filename__: record.bosNo + "_报关单", bosId: record.bosId}
        });
    }

    const printBGDHS = async () => {
        //const win = window.open('about:blank');
        //win.location.href = "https://crop-1257315785.cos.ap-shanghai.myqcloud.com/sumec/demo/print/%E6%88%90%E5%93%81%E4%BA%A4%E4%BB%98/%E5%87%BA%E5%8F%A3%E6%8A%A5%E5%85%B3%E5%8D%95%E6%89%93%E5%8D%B0%E6%96%87%E6%A1%A3.pdf"
        Tools.openReportView({
            code: 'dlv_bos_declare_row_hs',
            payload: {__filename__: record.bosNo + "_报关单(含申报要素)", bosId: record.bosId}
        });
    }

    const printBGDHSNested = async () => {
        //const win = window.open('about:blank');
        //win.location.href = "https://crop-1257315785.cos.ap-shanghai.myqcloud.com/sumec/demo/print/%E6%88%90%E5%93%81%E4%BA%A4%E4%BB%98/%E5%87%BA%E5%8F%A3%E6%8A%A5%E5%85%B3%E5%8D%95%E6%89%93%E5%8D%B0%E6%96%87%E6%A1%A3.pdf"
        Tools.openReportView({
            code: 'dlv_bos_declare_row_hs_nested',
            payload: {__filename__: record.bosNo + "_报关单(含申报要素)", bosId: record.bosId}
        });
    }

    const printHS = async () => {

        //const win = window.open('about:blank');
        //win.location.href = "https://crop-1257315785.cos.ap-shanghai.myqcloud.com/sumec/demo/print/%E6%88%90%E5%93%81%E4%BA%A4%E4%BB%98/%E5%87%BA%E5%8F%A3%E6%8A%A5%E5%85%B3%E5%8D%95%E6%89%93%E5%8D%B0%E6%96%87%E6%A1%A3.pdf"
        //Tools.openReportView({code: 'pbos_hs_declare', payload: {__filename__: record.bosNo + "_申报要素", bosId:record.bosId}});
        console.log(record)
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post("/dlv/bos/listDlvBosDocDRs", {bosId: record.bosId})
            },
            {
                error: {content: (err) => `查询失败: ${err.message}`},
                loading: {show: false},
                success: {show: false},
            }
        );
        console.log(data)
        if (!data || data.length === 0) {
            Modal.info({title: "提示", content: "没有要下载的文件"})
            return;
        }

        try {
            const dsDocResId = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/lib/docset/downloadDsDocDRs", {
                        dsDocList: data,
                        billType: "bos",
                        billNo: record?.bosNo,
                    })
                },
                {
                    error: {content: (err) => `下载失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            //await doRowDownloadzip(dsDocResId);
            window.location.href = Tools.convertToUrl(dsDocResId);
        } finally {
            // setMbosSelectRows([]);
            // setMsnSelectRows([])
        }

    }

    const printProxBGD = async () => {
        //const win = window.open('about:blank');
        //win.location.href = "https://crop-1257315785.cos.ap-shanghai.myqcloud.com/sumec/demo/print/%E6%88%90%E5%93%81%E4%BA%A4%E4%BB%98/%E5%87%BA%E5%8F%A3%E6%8A%A5%E5%85%B3%E5%8D%95%E6%89%93%E5%8D%B0%E6%96%87%E6%A1%A3.pdf"
        Tools.openReportView({
            code: 'proxy_customs_declaration',
            payload: {__filename__: record.bosNo + "_报关委托书", bosId: record.bosId}
        });
    }
    const printProxBGDNested = async () => {
        //const win = window.open('about:blank');
        //win.location.href = "https://crop-1257315785.cos.ap-shanghai.myqcloud.com/sumec/demo/print/%E6%88%90%E5%93%81%E4%BA%A4%E4%BB%98/%E5%87%BA%E5%8F%A3%E6%8A%A5%E5%85%B3%E5%8D%95%E6%89%93%E5%8D%B0%E6%96%87%E6%A1%A3.pdf"
        Tools.openReportView({
            code: 'proxy_customs_declaration_nested',
            payload: {__filename__: record.bosNo + "_报关委托书", bosId: record.bosId}
        });
    }
    const printSaleContract = async () => {
        //const win = window.open('about:blank');
        //win.location.href = "https://crop-1257315785.cos.ap-shanghai.myqcloud.com/sumec/demo/print/%E6%88%90%E5%93%81%E4%BA%A4%E4%BB%98/%E5%87%BA%E5%8F%A3%E6%8A%A5%E5%85%B3%E5%8D%95%E6%89%93%E5%8D%B0%E6%96%87%E6%A1%A3.pdf"
        Tools.openReportView({
            code: 'dlvpbos_sales_contract',
            payload: {__filename__: record.bosNo + "_销售合同", dlvBosId: record.bosId}
        });
    }


    const getReportName = (reportCode) => {
        switch (reportCode) {
            case "invoice_1st_bdr":
                return '_COMMERCIAL INVOICE';
            case "invoice_1st_bdr_yf":
                return '_COMMERCIAL INVOICE';
            case "packing_1st_bdr":
                return '_PACKING LIST';
            case "packing_1st_bdr_yf":
                return '_PACKING LIST';
            default:
                return '';
        }
    }

    // 执行打印
    const doPrint = async () => {
        try {
            setLoadingConfirm(true);
            let param = await formPrintSet.validateFields();
            Tools.openReportView({
                code: param.reportCode, payload: {
                    __filename__: record.bosNo + getReportName(param.reportCode),
                    bosId: record.bosId,
                    billTo: param.billTo ?? false,
                    companyTitle: param.companyTitle ?? false,
                    barSeal: param.barSeal ?? false,
                }
            });
        } finally {
            setLoadingConfirm(false);
            setVisiblePrintSet(false);
        }
    }

    const menuDeclare = (
        <Menu>
            <Menu.Item key="1">
                <div onClick={() => printFP()}>发票
                </div>
            </Menu.Item>
            <Menu.Item key="2">
                <div onClick={() => printZXD()}>装箱单
                </div>
            </Menu.Item>
            <Menu.Item key="3">
                <div onClick={() => printBGD()}>报关单
                </div>
            </Menu.Item>
            <Menu.Item key="4">
                <div onClick={() => printBGDNested()}>报关单(套打)
                </div>
            </Menu.Item>
            <Menu.Item key="5">
                <div onClick={() => printBGDHS()}>报关单(含申报要素)
                </div>
            </Menu.Item>
            <Menu.Item key="6">
                <div onClick={() => printBGDHSNested()}>报关单(含申报要素)(套打)
                </div>
            </Menu.Item>
            <Menu.Item key="7">
                <div onClick={() => printHS()}>申报要素
                </div>
            </Menu.Item>
            <Menu.Item key="8">
                <div onClick={() => printProxBGD()}>报关委托书
                </div>
            </Menu.Item>
            <Menu.Item key="9">
                <div onClick={() => printProxBGDNested()}>报关委托书(套打)
                </div>
            </Menu.Item>
            <Menu.Item key="10">
                <div onClick={() => printSaleContract()}>销售合同
                </div>
            </Menu.Item>
        </Menu>
    );

    const menu2 = (
        <Menu>
            <Menu.Item key="1">
                <div onClick={() => printYFFP()}>发票
                </div>
            </Menu.Item>
            <Menu.Item key="4">
                <div onClick={() => printYFZXD()}>装箱单
                </div>
            </Menu.Item>
        </Menu>
    );

    // 点击修改运编号时，出运单需处于草稿、待托单、改单、审批退回状态，否则不能修改运编号，需要给用户提示
    const altNum = async () => {
        if (!((dlvBos.bosStsCode == "DRAFT" || dlvBos.bosStsCode == "TOSUBMIT" || dlvBos.bosStsCode == "RETURN" || dlvBos.bosStsCode == "ALT") && ((dlvBos.cdCancelFlag ?? false) == false))) {
            message.warning("出运单需处于草稿、待托单、改单、审批退回状态，并且未退关，否则不能修改运编号!");
            return;
        }
        setVisibleAltNum(true);
    }

    //定义通用导航标题
    const navigation = {
        navigationTitle: <div>出运登记【<span className="text-color-primary">{dlvBos.bosNo}</span>】</div>,
        navigationDescriptions: [
            {
                // label: <span className="text-color-primary">{dictData.getDictDtlCn("BOS_STS",dlvBos.bosStsCode)}</span>,
                label: <span
                    className="text-color-primary">{dictData.getDictDtlCn("PC_STS", dlvBos.pbosReviewStsCode)}</span>,
            },
        ],
        navigationButtons: [
            // <Button size="small" type="primary" disabled={!(hasEditPermDS || hasEditPermSM)} onClick={() => setVisibleLabel(true)}
            //         style={{marginLeft: "100px"}}>唛头</Button>,
            // <Button size="small" type="primary" disabled={!(hasEditPermDS || hasEditPermSM)} onClick={() => altNum()}>修改运编号</Button>,
            fdpFlag && <Dropdown.Button overlay={menuDeclare} type="primary" size="small" ghost
                // disabled={dlvBos?.bosStsCode!=='EAOK'}
            > 报关资料打印 </Dropdown.Button>,
            // <Dropdown.Button overlay={menu2} type="primary" size="small" ghost disabled={dlvBos?.bosStsCode!=='EAOK'}> 议付资料打印 </Dropdown.Button>

        ]
    }

    const onFrameCall4BosList = async () => {
        // 刷新frame
        if (onFrameCall)
            onFrameCall();
    }
    const frames = [
        {
            key: 'GBOSFrame',
            name: '出运登记详情',
            children: [
                {
                    key: 'FPTSRDtlBase',
                    name: '首页',
                    frame: 'GAR_DY/GAR_BOS/GBOSFrmHome',
                    props: {
                        record: dlvBos,
                        onFrameCall: changeBos,
                        hasEditPerm: hasEditPermDS,
                        selfControlEditMode,
                        setSelfControlEditMode,
                        mainPageQuery: mainPageQuery, docToFlag,isGar, ...props
                    },
                },
                {
                    key: 'FPTSRDtlGoodsList',
                    name: '商品明细',
                    frame: 'GAR_DY/GAR_BOS/GBOSFrmIniRowNew',
                    props: {
                        record: dlvBos,
                        onFrameCall: changeBos,
                        hasEditPerm: hasEditPermDS,
                        selfControlEditMode,
                        setSelfControlEditMode, docToFlag,isGar, ...props
                    },
                },

                {
                    key: '托单评审',
                    name: '托单评审',
                    children: [
                        // {
                        //     key: 'GBOSFrmReviewCredit',
                        //     name: '信用险',
                        //     frame: 'GAR_DY/GAR_BOS/GBOSFrmReviewCredit',
                        //     props: { record:dlvBos, onFrameCall: init,hasEditPerm: hasEditPermDS, selfControlEditMode, setSelfControlEditMode, ...props },
                        // },
                        {
                            key: 'GBOSFrmReview',
                            name: '评审信息',
                            frame: 'GAR_DY/GAR_BOS/GBOSFrmReview',
                            props: {
                                record: dlvBos,
                                onFrameCall: init,
                                hasEditPerm: hasEditPermDS,
                                hasChangePerm: hasChangePermDS,
                                selfControlEditMode,
                                setSelfControlEditMode, docToFlag,isGar, ...props
                            },
                        },
                        // !ffpFlag && {
                        //     key: 'GBOSFrmReviewDeclare',
                        //     name: '报关信息',
                        //     frame: 'GAR_DY/GAR_BOS/GBOSFrmReviewDeclare',
                        //     props: { record:dlvBos, mainPageQuery: mainPageQuery, ...props },
                        // },
                        fdpFlag && {
                            key: 'GBOSFrmReviewDeclare',
                            name: '关单信息',
                            frame: 'GAR_DY/GAR_BOS/GBOSFrmCdDocset',
                            props: {
                                record: dlvBos,
                                mainPageQuery: mainPageQuery,
                                hasEditPerm: hasEditPermDS,
                                filePath: filePath, docToFlag,isGar, ...props
                            },
                        },
                    ].filter((r) => {
                        return r
                    }),
                },
                !isGar && {
                    key: '物流信息',
                    name: '物流信息',
                    children: [
                        !ffpFlag ? {
                            key: 'GBOSFrmLog',
                            name: '订舱信息',
                            frame: 'GAR_DY/GAR_BOS/GBOSFrmLog',
                            props: {
                                record: dlvBos,
                                mainPageQuery: mainPageQuery,
                                hasEditPerm: hasEditPermDS,
                                filePath, docToFlag, isGar, ...props
                            },
                            }:
                            {
                            key: 'GBOSFrmLog',
                            name: '订舱信息',
                            frame: 'GAR_DY/GAR_BOS/GBOSFrmWolNotice',
                            props: { record:dlvBos, mainPageQuery: mainPageQuery,hasEditPerm: hasEditPermDS, filePath, ...props },
                        },
                        {
                            key: 'GBOSFrmLogLadBill',
                            name: '提单信息',
                            frame: 'GAR_DY/GAR_BOS/GBOSFrmLogLadBill',
                            props: {
                                record: dlvBos,
                                mainPageQuery: mainPageQuery,
                                hasEditPerm: hasEditPermDS,
                                filePath, docToFlag,isGar, ...props
                            },
                        },
                        {
                            key: 'GBOSFrmReviewTI',
                            name: '货运险',
                            frame: 'GAR_DY/GAR_BOS/GBOSFrmReviewTI',
                            props: {
                                record: dlvBos,
                                onFrameCall: init,
                                hasEditPerm: hasEditPermDS,
                                hasChangePerm: hasChangePermDS, docToFlag,isGar, ...props
                            },
                        },
                        {
                            key: 'GBOSFrmLogLineQC',
                            name: '目的地进仓信息',
                            frame: 'GAR_DY/GAR_BOS/GBOSFrmLogLineQC',
                            props: {
                                record: dlvBos,
                                mainPageQuery: mainPageQuery,
                                hasEditPerm: hasEditPermDS,
                                filePath, docToFlag,isGar, ...props
                            },
                        },
                        {
                            key: 'GBOSFrmLogCost',
                            name: '费用分摊',
                            frame: 'GAR_DY/GAR_BOS/GBOSFrmLogCost',
                            props: {
                                record: dlvBos,
                                onFrameCall: onFrameCall4BosList,
                                mainPageQuery: mainPageQuery,
                                hasEditPerm: hasEditPermDS, docToFlag,isGar, ...props
                            },
                        }
                    ].filter((r) => {
                        return r
                    }),
                },
                // {
                //     key: '关单信息',
                //     name: '关单信息',
                //     children: [
                //         {
                //             key: 'FPTSRDtlDeclare',
                //             name: '正本关单',
                //             frame: 'GAR_DY/GAR_BOS/GBOSFrmDeclareCD',
                //             props: { record:dlvBos, mainPageQuery: mainPageQuery, bosType:'PBOS', hasEditPerm: hasEditPermDS || hasEditPermSM ,...props },
                //         },
                //         isGar?{
                //             key: 'GBOSFrmCostCal',
                //             name: '成本测算',
                //             frame: 'GAR_DY/GAR_BOS/GBOSFrmCostCalGar',
                //             props: { record:dlvBos, mainPageQuery: mainPageQuery,onFrameCall: init,hasEditPerm: hasEditPermSM,hasChangePerm: hasChangePermSM, ...props },
                //         }:{
                //             key: 'GBOSFrmCostCalPro',
                //             name: '成本测算',
                //             frame: 'GAR_DY/GAR_BOS/GBOSFrmCostCalPro',
                //             props: { record:dlvBos, mainPageQuery: mainPageQuery, ...props },
                //         },
                //         isGar && {
                //             key: 'FPTSRDtlDecRelCost',
                //             name: '关联成本',
                //             frame: 'GAR_DY/GAR_BOS/GBOSFrmDeclarerRealCost',
                //             props: { record:dlvBos,mainPageQuery: mainPageQuery,onFrameCall: init,hasEditPerm: hasEditPermSM,...props },
                //         },
                //         isGar && {
                //             key: 'FPTSRDtlDecBill',
                //             name: '开票通知',
                //             frame: 'GAR_DY/GAR_BOS/GBOSFrmDeclareSIN',
                //             props: { record:dlvBos, mainPageQuery: mainPageQuery,onFrameCall: init,hasEditPerm: hasEditPermSM,...props },
                //         },
                //     ].filter((r) => { return r })
                // },
                !isGar && {
                    key: 'GBOSFrmIncome',
                    name: '来款信息',
                    frame: 'GAR_DY/GAR_BOS/GBOSFrmIncome',
                    props: {
                        record: dlvBos,
                        mainPageQuery: mainPageQuery,
                        hasEditPerm: hasEditPermDS,
                        docToFlag,isGar, ...props
                    },
                    /*children: [
                        {
                            key: 'GBOSFrmIncome',
                            name: '收入明细',
                            frame: 'GAR_DY/GAR_BOS/GBOSFrmIncome',
                            props: { record, mainPageQuery: mainPageQuery, ...props },
                        },
                        /!*{
                            key: 'GBOSFrmIncomeMerNeg',
                            name: '合并议付',
                            frame: 'GAR_DY/GAR_BOS/GBOSFrmIncomeMerNeg',
                            props: { record, mainPageQuery: mainPageQuery, ...props },
                        }*!/
                    ],*/

                },
                !isGar && {
                    key: 'FPTSRDtlCost',
                    name: '费用信息',
                    frame: 'GAR_DY/GAR_BOS/GBOSFrmCost',
                    props: {
                        record: dlvBos,
                        mainPageQuery: mainPageQuery,
                        hasEditPerm: hasEditPermDS,
                        docToFlag,isGar, ...props
                    },
                },
                !isGar && {
                    key: 'GBOSFrmLogDoc',
                    name: '附件上传',
                    frame: 'GAR_DY/GAR_BOS/GBOSFrmLogDoc',
                    props: {
                        record: dlvBos,
                        billType: 'GBOS',
                        mainPageQuery: mainPageQuery,
                        hasEditPerm: hasEditPermDS,
                        filePath, docToFlag,isGar, ...props
                    },
                },
            ].filter((r) => {
                return r
            }),
        },
    ].filter((r) => {
        return r
    });


    return <>
        <Frame frames={frames} navigation={navigation} defaultKey={defaultPaneKey ? defaultPaneKey : 'FPTSRDtlBase'}/>
        <DlgLabel visible={visibleLabel} setVisible={setVisibleLabel} record={dlvBos}/>
        <DlgGBOSAltNumber visible={visibleAltNum} setVisible={setVisibleAltNum} record={dlvBos}
                          hasEditPerm={hasEditPermDS} onFrameCall={init} billType={"PBOS"}/>

        <DraggableModal
            visible={visiblePrintSet}
            title='打印设置'
            okText="确定"
            cancelText="取消"
            onOk={() => doPrint()}
            width="30%"
            onCancel={() => setVisiblePrintSet(false)}
            confirmLoading={loadingConfirm}
        >
            <Form form={formPrintSet} size="small" layout="horizontal">
                <Row gutter={24}>
                    <Col span={24} style={{display: 'none'}}>
                        <Form.Item name='reportCode' label={"打印报表"}>
                            <ReadyInput/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    {printType == 'FP' && <Col span={24}>
                        <Form.Item name='billTo' valuePropName={"checked"} label={""}>
                            <Checkbox>含Bll To</Checkbox>
                        </Form.Item>
                    </Col>}
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item name='companyTitle' valuePropName={"checked"} label={""}>
                            <Checkbox>含公司抬头</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item name='barSeal' valuePropName={"checked"} label={""}>
                            <Checkbox>含条形章</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </DraggableModal>
    </>;
}

export default GBOSFrame;
