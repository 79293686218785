
const validatior = () => {

}

const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
validatior.EmailRegex = emailReg;

validatior.isPhoneNumber = (value) => {
    let phoneReg = /^[1][3,4,5,7,8][0-9]{9}$/;
    if (!phoneReg.test(value)) {
        return false
    }
    return true;
}

validatior.isEmail = (value) => {
    if (!emailReg.test(value)) {
        return false
    }
    return true;
}

export default validatior;
