import runAsync from "./runAsync.mjs";
import http from "./http.mjs";
import authenticator from "script/sweet-authenticator.mjs";
import validatior from "./sweet-validator.mjs";
import useGlobalData from "hooks/useGlobalData.mjs";
import {Modal} from 'antd';

/**
 * 通用工具集合
 * @constructor
 */
const Tools = () => {

}

/**
 * 后端服务域名1
 * @type {string | boolean}
 */
Tools.BAS_URL = process.env.REACT_APP_HTTP_BASE_URL;

/**
 * 文件服务域名
 * @type {string}
 */
Tools.BAS_FILE_URL = process.env.REACT_APP_HTTP_BASE_URL + "/bas/files";

/**
 * Session Key
 * @type {string | boolean}
 */
Tools.SESSION_KEY = process.env.REACT_APP_SESSION;
/**
 * 打开一个报表的展示页
 * @param code
 * @param custId
 * @param payload
 */
Tools.openReportView = ({code, custId, payload = {}}) => {
    const template = useGlobalData.getRT({code, custId})
    const template1 = useGlobalData.getRTTOKEN()
    if (template) {
        let resource = new window.URL(template.domain);

        resource.searchParams.append("viewlet", template.rtValue);

        for (const [key, value] of Object.entries(payload)) {
            if (undefined !== value && null !== value) {
                if (value instanceof Array) {
                    for (var v of value) {
                        resource.searchParams.append(key, v);
                    }
                } else {
                    resource.searchParams.set(key, value);
                }
            }
        }
        if(template1) {
            let  url =   `${template1.ossLoginUrl}/webroot/decision/ssologin?redirect=${encodeURIComponent(resource)}&token=${template1.token}`
            window.open(url);
        } else {
            Modal.error({content: '获取帆软报表token失败'})
        }
        
    } else {
        Modal.error({content: '未找到配置信息'})
    }
}

/**
 * 打开多个报表的合并展示页
 * @param reportlets
 */
Tools.openMultipleReportView = ({reportlets = [], payload = {}}) => {
    let domain;
    const template1 = useGlobalData.getRTTOKEN()
    const param = reportlets.map(({code, custId, ...props}) => {
        const template = useGlobalData.getRT({code, custId})
        if (template) {
            if (!domain) {
                domain = template.domain;
            }
            return {
                reportlet: template.rtValue,
                ...props
            };
        }
        return {}
    })
    let resource = new window.URL(domain);

    resource.searchParams.append("reportlets", JSON.stringify(param))
    for (const [key, value] of Object.entries(payload)) {
        if (undefined !== value && null !== value) {
            if (value instanceof Array) {
                for (var v of value) {
                    resource.searchParams.append(key, v);
                }
            } else {
                resource.searchParams.set(key, value);
            }
        }
    }
    let  url =   `${template1.ossLoginUrl}/webroot/decision/ssologin?redirect=${encodeURIComponent(resource.href)}&token=${template1.token}`
    window.open(url);
}

/**
 * 获得一个报表的展示页 URL
 * 用于嵌入式展示
 * @param code
 * @param custId
 * @param payload
 * @returns {string|null}
 */
Tools.convertReportViewUrl = ({code, custId, payload = {}}) => {
    const template = useGlobalData.getRT({code, custId})
    const template1 = useGlobalData.getRTTOKEN()
    if (template && template1) {
        let resource = new window.URL(template.domain);

        resource.searchParams.append("viewlet", template.rtValue);

        for (const [key, value] of Object.entries(payload)) {
            if (undefined !== value && null !== value) {
                if (value instanceof Array) {
                    for (var v of value) {
                        resource.searchParams.append(key, v);
                    }
                } else {
                    resource.searchParams.set(key, value);
                }
            }
        }
        if(template1) {
            let  url =   `${template1.ossLoginUrl}/webroot/decision/ssologin?redirect=${encodeURIComponent(resource.href)}&token=${template1.token}`
            return url;
        } else {
            Modal.error({content: '未找到配置信息'})
        }
       
    } else {
        Modal.error({content: '未找到配置信息'})
    }
    return null;
}

/**
 * 获取多个报表的合并URL
 * @param reportlets
 */
Tools.convertMultipleReportUrl = ({reportlets = [], payload = {}}) => {
    let domain;
    const template1 = useGlobalData.getRTTOKEN()
    const param = reportlets.map(({code, custId, ...props}) => {
        const template = useGlobalData.getRT({code, custId})
        if (template) {
            if (!domain) {
                domain = template.domain;
            }
            return {
                reportlet: template.rtValue,
                ...props
            };
        }
        return {}
    })
    let resource = new window.URL(domain);

    resource.searchParams.append("reportlets", JSON.stringify(param))
    for (const [key, value] of Object.entries(payload)) {
        if (undefined !== value && null !== value) {
            if (value instanceof Array) {
                for (var v of value) {
                    resource.searchParams.append(key, v);
                }
            } else {
                resource.searchParams.set(key, value);
            }
        }
    }
    let  url =   `${template1.ossLoginUrl}/webroot/decision/ssologin?redirect=${encodeURIComponent(resource.href)}&token=${template1.token}`
    return url;
}
/**
 * 将文件 ID 转换为完整的文件路径
 * @param fileId 文件 ID
 * @returns {*}
 */
Tools.convertToUrl = (fileId, fileName, contentType) => {
    let resource = new window.URL(Tools.BAS_URL + `/bas/files/${fileId}`)
    if (fileName) {
        resource.searchParams.append("fileName", fileName)
    }
    if (contentType) {
        resource.searchParams.append("contentType", contentType)
    }
    return resource.href;
}

/**
 * 分割查询字符串， 返回数组
 * 适用模拟全文搜索需要分词的场景，默认以空格作为分隔符
 * @param str 必传
 * @param separator 可选。字符串或正则表达式，从该参数指定的地方分割 string Object
 * @returns {null|*}
 */
Tools.splitSearchStr = (str, separator = /\s+/) => {
    if (str && str.trim().length > 0) {
        return str.trim().split(separator);
    } else {
        return null;
    }
}

Tools.http = http;
Tools.runAsync = runAsync;
Tools.authenticator = authenticator;
Tools.validatior = validatior;

export {http, runAsync}
export default Tools
